import axios from 'axios'
import store from '../store/index'
import router from '../router';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { getToken, removeToken, removeUserDetail, getStoreCode, getUserDetail, removeNodeId, removeSessionId } from '@/utils/localStorage'

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000,
})
let deviceId = null;
FingerprintJS.load().then((fp) => {
  fp.get().then((result) => {
    deviceId = result.visitorId;
  })
})
request.interceptors.request.use(
  (config) => {
    let token
    if (store.state.token) {
      token = store.state.token
    }
    // else {
    //   token = 'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl9leHBpcmVfdGltZSI6MTY2ODQxNDA1OTYzOSwibG9naW5fdXNlcl9rZXkiOiJhcHA6MTg3In0.uUtoOFIjflouAUrSnP407YihICFfMJ3Anh71ARJ0ZgpDptvXVq498BfQHoPxodw7jhJCAmMKtzBJmK3rXchJiw'
    // }
    let systemName = "IOS";
    let u = navigator.userAgent;
    let storeMsg = getStoreCode();
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端
    if (!isIOS) {
      systemName = "Android";
    }
    let hostname = window.location.hostname;
    config.headers['Device-Id'] = deviceId;
    config.headers['domain'] = hostname;
    config.headers['System-Name'] = systemName;
    config.headers['Store-Msg'] = storeMsg;
    // config.headers.Authorization = `Bearer ${token}`
    config.headers.Authorization = token
    return config
  },
  (error) => Promise.reject(error),
)

request.interceptors.response.use(
  response => {
    // 状态码
    const code = response.data.code;
    // 获取错误信息
    const msg = response.data.msg;
    //分销页面
    const whiteList = store.state.whiteList;
    if (code === 401) {
      removeToken();
      removeUserDetail();
      removeNodeId();
      removeSessionId();
      store.state.token = '';
      store.state.nodeId = '';
      store.state.sessionId = '';
      var path = store.state.lastTimePath || window.location.pathname;
      // 判断是否为白名单路径
      if (whiteList.indexOf(path) === -1) {
        router.push('/');
      }
      return Promise.reject('無效的會話，或者會話已過期，請重新登錄。')
    } else {
      return response.data
    }
  },
  error => Promise.reject(error),
)

export default request
