// jp.js
// 系统公共语言
const system = {
  systemName: 'ラッキーモール',
}

// 登录模块
const login = {
  tab: 'ログイン',
  tab2: '登録',
  account: 'アカウント',
  password: 'パスワード',
  code: 'コード',
  accountPl: '電話番号を入力',
  passwordPl: 'バスワードを入力',
  passwordPl2: 'バスワードを入力',
  codePl: 'コードを入力',
  sendCode: 'コードを送信する',
  resend: '再送信',
  forgetPassword: 'バスワードを忘れた場合',
  login: 'ログイン',
  loging: '...',
  register: '登録',
  reset: 'バスワードを変更',
  resetHint: '電話番号やメールアドレスを入力して認証コードを取得し、認証コードまたは新しいバスワードを入力します',
}

// 首页模块
const home = {
  title: 'ラッキーモール',
}

export default {
  login: login,
  system: system,
  home: home
}
