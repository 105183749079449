// index.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import thLocale from './th/th'
import jpLocale from './jp/jp'
import zhTWLocale from './zhTW/zhTW'
import { Locale } from 'vant';
import zhTW from "vant/es/locale/lang/zh-TW";
import zhTH from "vant/es/locale/lang/th-TH";
import jaJP from "vant/es/locale/lang/ja-JP";

Vue.use(VueI18n)

const messages = {
  th: {
    ...thLocale,
    ...zhTH,
  },
  jp: {
    ...jpLocale,
    ...jaJP,
  },
  zhTW: {
    ...zhTWLocale,
    ...zhTW,
  }
}

const i18n = new VueI18n({
  // 设置语言 选项 th | zhTW | jp
  locale: Cookies.get('language') || 'zhTW',
  // 设置文本内容
  messages,
  // 无配置参数去掉浏览器控制台 warning
  silentTranslationWarn: true
})

// 更新vant组件库本身的语言变化，支持国际化
function vantLocales(lang) {
  if (lang === 'jp') {
    Locale.use(lang, jaJP)
  } else if (lang === 'zhTW') {
    Locale.use(lang, zhTW)
  } else if (lang === 'th') {
    Locale.use(lang, zhTH)
  }
}

export { i18n, vantLocales }
