import request from '@/utils/request'

// 用户操作记录列表
export function addlog(cid = null, data) {
  return request({
    method: 'post',
    headers: { "Device-Id": cid },
    url: '/api/oper/log/add',
    data,
  })
}
