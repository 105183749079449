import store from '../store/index'

// Cookie工具类，用于存储和获取cookie
const TOKEN_KEY = 'boxLucky_token'
const USET_KEY = 'user_details'
const NODE_KEY = 'node_Id'
const SESSION_KEY = 'session_id'
const STORE_CODE = 'store_code'
const STORE_STOREINFO = 'store_storeinfo' // 门店信息
// 保存token
export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token)
}
// 获取token
export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY)
}
// 删除token
export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY)
  // store.commit("token", "");
}
// 保存用户信息
export const setUserDetail = (detail) => {
  localStorage.setItem(USET_KEY, JSON.stringify(detail))
}
// 获取用户信息
export const getUserDetail = () => {
  return JSON.parse(localStorage.getItem(USET_KEY))
}
// 删除用户信息
export const removeUserDetail = () => {
  localStorage.removeItem(USET_KEY)
  // store.commit("userDetail", "");
}
// 保存nodeId
export const setNodeId = (value) => {
  localStorage.setItem(NODE_KEY, value)
}
// 获取nodeId信息
export const getNodeId = () => {
  return localStorage.getItem(NODE_KEY)
}
// 删除nodeId信息
export const removeNodeId = () => {
  localStorage.removeItem(NODE_KEY)
  store.commit("setNodeId", "");
}
// 保存sessionId
export const setSessionId = (value) => {
  localStorage.setItem(SESSION_KEY, value)
}
// 获取sessionId信息
export const getSessionId = () => {
  return localStorage.getItem(SESSION_KEY)
}
// 删除sessionId信息
export const removeSessionId = () => {
  localStorage.removeItem(SESSION_KEY)
  store.commit("setSessionId", "");
}
// 保存storeCode
export const setStoreCode = (value) => {
  localStorage.setItem(STORE_CODE, value)
  // store.commit("setStoreCode", value);
}
// 获取storeCode信息
export const getStoreCode = () => {
  return localStorage.getItem(STORE_CODE)
}
// 删除storeCode信息
export const removeStoreCode = () => {
  localStorage.removeItem(STORE_CODE)
  // store.commit("setStoreCode", "");
}
// 存储店铺信息
export const setStoreInfo = (storeInfo => {
  localStorage.setItem(STORE_STOREINFO, storeInfo)
})
// 获取店铺信息
export const getStoreInfo = (() => {
  return localStorage.getItem(STORE_STOREINFO)
})
// 存储店铺信息
export const removeStoreInfo = (() => {
  localStorage.removeItem(STORE_STOREINFO)
})
